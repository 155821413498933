var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', _vm._g({
    attrs: {
      "viewBox": "0 0 12 14",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, _vm.$listeners), [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M6 .75a1 1 0 011 1v8.086l2.793-2.793a1 1 0 111.414 1.414l-4.5 4.5a1 1 0 01-1.414 0l-4.5-4.5a1 1 0 111.414-1.414L5 9.836V1.75a1 1 0 011-1z"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }