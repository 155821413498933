import toCamelCase from 'lodash/camelCase'
import { OpenAPIClientAxios, Document as OpenAPI3Document } from '@fedorif/openapi-client-axios-custom'
import clientAdminDefinition from '@/swagger/admin.json'
import { Client as ClientAdmin } from '@/types/client.admin'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Plugin } from '@nuxt/types'

declare module '@nuxt/types' {
    interface NuxtAppOptions {
        $apiAdmin: ClientAdmin
    }

    interface Context {
        $apiAdmin: ClientAdmin
    }
}

const AxiosClientAdmin: Plugin = async ({ $axios }, inject) => {
    const api = new OpenAPIClientAxios({
        transformOperationName: o => toCamelCase(o),
        definition: clientAdminDefinition as unknown as OpenAPI3Document,
        axiosInstance: $axios
    })

    const client = await api.getClient<ClientAdmin>()

    inject('apiAdmin', client)
}

export default AxiosClientAdmin
