import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _47d3b168 = () => interopDefault(import('../src/pages/admin/articles-category/index.vue' /* webpackChunkName: "pages/admin/articles-category/index" */))
const _eb3a8ff6 = () => interopDefault(import('../src/pages/admin/banners/index.vue' /* webpackChunkName: "pages/admin/banners/index" */))
const _4b55d13e = () => interopDefault(import('../src/pages/admin/bonus-conditions/index.vue' /* webpackChunkName: "pages/admin/bonus-conditions/index" */))
const _29f2bb70 = () => interopDefault(import('../src/pages/admin/bonuses.vue' /* webpackChunkName: "pages/admin/bonuses" */))
const _96db5a6a = () => interopDefault(import('../src/pages/admin/bonuses/index.vue' /* webpackChunkName: "pages/admin/bonuses/index" */))
const _211f1036 = () => interopDefault(import('../src/pages/admin/bonuses/_id/index.vue' /* webpackChunkName: "pages/admin/bonuses/_id/index" */))
const _1a6e4ff3 = () => interopDefault(import('../src/pages/admin/cabinets/index.vue' /* webpackChunkName: "pages/admin/cabinets/index" */))
const _8c44b4f0 = () => interopDefault(import('../src/pages/admin/calculator-integrations/index.vue' /* webpackChunkName: "pages/admin/calculator-integrations/index" */))
const _d3ec9a3e = () => interopDefault(import('../src/pages/admin/campaigns/index.vue' /* webpackChunkName: "pages/admin/campaigns/index" */))
const _24dfb474 = () => interopDefault(import('../src/pages/admin/categories/index.vue' /* webpackChunkName: "pages/admin/categories/index" */))
const _d13a0cfe = () => interopDefault(import('../src/pages/admin/communication-chains/index.vue' /* webpackChunkName: "pages/admin/communication-chains/index" */))
const _1b50e192 = () => interopDefault(import('../src/pages/admin/content/index.vue' /* webpackChunkName: "pages/admin/content/index" */))
const _ec9224c2 = () => interopDefault(import('../src/pages/admin/contracts/index.vue' /* webpackChunkName: "pages/admin/contracts/index" */))
const _56ba8894 = () => interopDefault(import('../src/pages/admin/courses/index.vue' /* webpackChunkName: "pages/admin/courses/index" */))
const _22c7444c = () => interopDefault(import('../src/pages/admin/excel-operations/index.vue' /* webpackChunkName: "pages/admin/excel-operations/index" */))
const _d736b5bc = () => interopDefault(import('../src/pages/admin/fast-filters/index.vue' /* webpackChunkName: "pages/admin/fast-filters/index" */))
const _4a223351 = () => interopDefault(import('../src/pages/admin/fields/index.vue' /* webpackChunkName: "pages/admin/fields/index" */))
const _262341e4 = () => interopDefault(import('../src/pages/admin/first-page/index.vue' /* webpackChunkName: "pages/admin/first-page/index" */))
const _72700c25 = () => interopDefault(import('../src/pages/admin/formulas/index.vue' /* webpackChunkName: "pages/admin/formulas/index" */))
const _a5f8f5ce = () => interopDefault(import('../src/pages/admin/help/index.vue' /* webpackChunkName: "pages/admin/help/index" */))
const _37807077 = () => interopDefault(import('../src/pages/admin/integrations/index.vue' /* webpackChunkName: "pages/admin/integrations/index" */))
const _5896d928 = () => interopDefault(import('../src/pages/admin/integrations-ast/index.vue' /* webpackChunkName: "pages/admin/integrations-ast/index" */))
const _f65b717e = () => interopDefault(import('../src/pages/admin/managers-office/index.vue' /* webpackChunkName: "pages/admin/managers-office/index" */))
const _7145d860 = () => interopDefault(import('../src/pages/admin/mass-finances/index.vue' /* webpackChunkName: "pages/admin/mass-finances/index" */))
const _6cfd49e4 = () => interopDefault(import('../src/pages/admin/mass-offers/index.vue' /* webpackChunkName: "pages/admin/mass-offers/index" */))
const _4b2aed23 = () => interopDefault(import('../src/pages/admin/mass-operations/index.vue' /* webpackChunkName: "pages/admin/mass-operations/index" */))
const _f398b952 = () => interopDefault(import('../src/pages/admin/mass-statuses/index.vue' /* webpackChunkName: "pages/admin/mass-statuses/index" */))
const _10a3de91 = () => interopDefault(import('../src/pages/admin/mortgage-calculator/index.vue' /* webpackChunkName: "pages/admin/mortgage-calculator/index" */))
const _3a830ab4 = () => interopDefault(import('../src/pages/admin/notifications/index.vue' /* webpackChunkName: "pages/admin/notifications/index" */))
const _4753f32c = () => interopDefault(import('../src/pages/admin/offers.vue' /* webpackChunkName: "pages/admin/offers" */))
const _1a9f2ea2 = () => interopDefault(import('../src/pages/admin/offers/index.vue' /* webpackChunkName: "pages/admin/offers/index" */))
const _54b7941a = () => interopDefault(import('../src/pages/admin/offers/_id/index.vue' /* webpackChunkName: "pages/admin/offers/_id/index" */))
const _080220fa = () => interopDefault(import('../src/pages/admin/partners/index.vue' /* webpackChunkName: "pages/admin/partners/index" */))
const _af1fff42 = () => interopDefault(import('../src/pages/admin/popups/index.vue' /* webpackChunkName: "pages/admin/popups/index" */))
const _07e4cc19 = () => interopDefault(import('../src/pages/admin/product-types/index.vue' /* webpackChunkName: "pages/admin/product-types/index" */))
const _744830fc = () => interopDefault(import('../src/pages/admin/products/index.vue' /* webpackChunkName: "pages/admin/products/index" */))
const _ea25510c = () => interopDefault(import('../src/pages/admin/promo-constructor/index.vue' /* webpackChunkName: "pages/admin/promo-constructor/index" */))
const _379fafdc = () => interopDefault(import('../src/pages/admin/promopage/index.vue' /* webpackChunkName: "pages/admin/promopage/index" */))
const _62ce9d1f = () => interopDefault(import('../src/pages/admin/publications/index.vue' /* webpackChunkName: "pages/admin/publications/index" */))
const _1a2f6e91 = () => interopDefault(import('../src/pages/admin/rates/index.vue' /* webpackChunkName: "pages/admin/rates/index" */))
const _d95b4c18 = () => interopDefault(import('../src/pages/admin/requests-limiter.vue' /* webpackChunkName: "pages/admin/requests-limiter" */))
const _2a3a600f = () => interopDefault(import('../src/pages/admin/requests-limiter.vue/index.vue' /* webpackChunkName: "pages/admin/requests-limiter.vue/index" */))
const _316be790 = () => interopDefault(import('../src/pages/admin/segments/index.vue' /* webpackChunkName: "pages/admin/segments/index" */))
const _17b80e0a = () => interopDefault(import('../src/pages/admin/settings/index.vue' /* webpackChunkName: "pages/admin/settings/index" */))
const _2aabd024 = () => interopDefault(import('../src/pages/admin/showcases/index.vue' /* webpackChunkName: "pages/admin/showcases/index" */))
const _4a519ade = () => interopDefault(import('../src/pages/admin/stories/index.vue' /* webpackChunkName: "pages/admin/stories/index" */))
const _5bb162de = () => interopDefault(import('../src/pages/admin/substatuses/index.vue' /* webpackChunkName: "pages/admin/substatuses/index" */))
const _5f80bfb6 = () => interopDefault(import('../src/pages/admin/tickets/index.vue' /* webpackChunkName: "pages/admin/tickets/index" */))
const _4ce3ccfe = () => interopDefault(import('../src/pages/admin/user-id-convert/index.vue' /* webpackChunkName: "pages/admin/user-id-convert/index" */))
const _73b1dec6 = () => interopDefault(import('../src/pages/admin/users/index.vue' /* webpackChunkName: "pages/admin/users/index" */))
const _562cdb44 = () => interopDefault(import('../src/pages/admin/video-comments/index.vue' /* webpackChunkName: "pages/admin/video-comments/index" */))
const _89c8847a = () => interopDefault(import('../src/pages/admin/video-tags/index.vue' /* webpackChunkName: "pages/admin/video-tags/index" */))
const _506f3530 = () => interopDefault(import('../src/pages/admin/videos/index.vue' /* webpackChunkName: "pages/admin/videos/index" */))
const _2f5ef15c = () => interopDefault(import('../src/pages/admin/withdrawals/index.vue' /* webpackChunkName: "pages/admin/withdrawals/index" */))
const _6f9abc52 = () => interopDefault(import('../src/pages/admin/auth/login.vue' /* webpackChunkName: "pages/admin/auth/login" */))
const _2b0e57f1 = () => interopDefault(import('../src/pages/admin/auth/success.vue' /* webpackChunkName: "pages/admin/auth/success" */))
const _6db89e55 = () => interopDefault(import('../src/pages/admin/courses/blocks/index.vue' /* webpackChunkName: "pages/admin/courses/blocks/index" */))
const _b75bcd2a = () => interopDefault(import('../src/pages/admin/courses/categories/index.vue' /* webpackChunkName: "pages/admin/courses/categories/index" */))
const _a7d433b0 = () => interopDefault(import('../src/pages/admin/courses/tags/index.vue' /* webpackChunkName: "pages/admin/courses/tags/index" */))
const _179d37e3 = () => interopDefault(import('../src/pages/admin/menu/customer-portal/index.vue' /* webpackChunkName: "pages/admin/menu/customer-portal/index" */))
const _13dfd5f3 = () => interopDefault(import('../src/pages/admin/promo-constructor/preview/index.vue' /* webpackChunkName: "pages/admin/promo-constructor/preview/index" */))
const _76040b62 = () => interopDefault(import('../src/pages/admin/withdrawals/archive/index.vue' /* webpackChunkName: "pages/admin/withdrawals/archive/index" */))
const _1e0705a4 = () => interopDefault(import('../src/pages/admin/mortgage-calculator/tables/mortgage-calculator-banks.vue' /* webpackChunkName: "pages/admin/mortgage-calculator/tables/mortgage-calculator-banks" */))
const _14184b3c = () => interopDefault(import('../src/pages/admin/mortgage-calculator/tables/mortgage-calculator-insurances.vue' /* webpackChunkName: "pages/admin/mortgage-calculator/tables/mortgage-calculator-insurances" */))
const _007ef280 = () => interopDefault(import('../src/pages/admin/courses/blocks/_id/index.vue' /* webpackChunkName: "pages/admin/courses/blocks/_id/index" */))
const _085a86d6 = () => interopDefault(import('../src/pages/admin/courses/categories/_id/index.vue' /* webpackChunkName: "pages/admin/courses/categories/_id/index" */))
const _8880a5da = () => interopDefault(import('../src/pages/admin/courses/tags/_id/index.vue' /* webpackChunkName: "pages/admin/courses/tags/_id/index" */))
const _571655d0 = () => interopDefault(import('../src/pages/admin/menu/customer-portal/_parentId/index.vue' /* webpackChunkName: "pages/admin/menu/customer-portal/_parentId/index" */))
const _a7a4b7fa = () => interopDefault(import('../src/pages/admin/menu/customer-portal/_parentId/_id/index.vue' /* webpackChunkName: "pages/admin/menu/customer-portal/_parentId/_id/index" */))
const _fc9a8792 = () => interopDefault(import('../src/pages/admin/articles-category/_id/index.vue' /* webpackChunkName: "pages/admin/articles-category/_id/index" */))
const _21664870 = () => interopDefault(import('../src/pages/admin/banners/_id/index.vue' /* webpackChunkName: "pages/admin/banners/_id/index" */))
const _d76fc5ae = () => interopDefault(import('../src/pages/admin/bonus-conditions/_id/index.vue' /* webpackChunkName: "pages/admin/bonus-conditions/_id/index" */))
const _10e8395e = () => interopDefault(import('../src/pages/admin/cabinets/_id/index.vue' /* webpackChunkName: "pages/admin/cabinets/_id/index" */))
const _bf0e0cee = () => interopDefault(import('../src/pages/admin/campaigns/_id.vue' /* webpackChunkName: "pages/admin/campaigns/_id" */))
const _0321755f = () => interopDefault(import('../src/pages/admin/categories/_id/index.vue' /* webpackChunkName: "pages/admin/categories/_id/index" */))
const _49316bec = () => interopDefault(import('../src/pages/admin/communication-chains/_id/index.vue' /* webpackChunkName: "pages/admin/communication-chains/_id/index" */))
const _47d836a2 = () => interopDefault(import('../src/pages/admin/content/_id/index.vue' /* webpackChunkName: "pages/admin/content/_id/index" */))
const _4a12210a = () => interopDefault(import('../src/pages/admin/contracts/_id/index.vue' /* webpackChunkName: "pages/admin/contracts/_id/index" */))
const _7fab0ba1 = () => interopDefault(import('../src/pages/admin/courses/_id/index.vue' /* webpackChunkName: "pages/admin/courses/_id/index" */))
const _d2a6a926 = () => interopDefault(import('../src/pages/admin/excel-finance/_id/index.vue' /* webpackChunkName: "pages/admin/excel-finance/_id/index" */))
const _362559b9 = () => interopDefault(import('../src/pages/admin/excel-statuses/_id/index.vue' /* webpackChunkName: "pages/admin/excel-statuses/_id/index" */))
const _50425f0d = () => interopDefault(import('../src/pages/admin/fast-filters/_id/index.vue' /* webpackChunkName: "pages/admin/fast-filters/_id/index" */))
const _99c18488 = () => interopDefault(import('../src/pages/admin/fields/_id/index.vue' /* webpackChunkName: "pages/admin/fields/_id/index" */))
const _4726e6e0 = () => interopDefault(import('../src/pages/admin/formulas/_id/index.vue' /* webpackChunkName: "pages/admin/formulas/_id/index" */))
const _7d15ada6 = () => interopDefault(import('../src/pages/admin/help/_sectionId/index.vue' /* webpackChunkName: "pages/admin/help/_sectionId/index" */))
const _5ee7c6bb = () => interopDefault(import('../src/pages/admin/mass-finances/_id/index.vue' /* webpackChunkName: "pages/admin/mass-finances/_id/index" */))
const _ca0b820e = () => interopDefault(import('../src/pages/admin/mass-offers/_id/index.vue' /* webpackChunkName: "pages/admin/mass-offers/_id/index" */))
const _2ab2be8e = () => interopDefault(import('../src/pages/admin/mass-operations/_id/index.vue' /* webpackChunkName: "pages/admin/mass-operations/_id/index" */))
const _ad658a7c = () => interopDefault(import('../src/pages/admin/mass-statuses/_id/index.vue' /* webpackChunkName: "pages/admin/mass-statuses/_id/index" */))
const _ea245ade = () => interopDefault(import('../src/pages/admin/notifications/_id/index.vue' /* webpackChunkName: "pages/admin/notifications/_id/index" */))
const _72fe90ee = () => interopDefault(import('../src/pages/admin/partners/_id/index.vue' /* webpackChunkName: "pages/admin/partners/_id/index" */))
const _6c83d3ca = () => interopDefault(import('../src/pages/admin/popups/_id/index.vue' /* webpackChunkName: "pages/admin/popups/_id/index" */))
const _28d83284 = () => interopDefault(import('../src/pages/admin/product-types/_id/index.vue' /* webpackChunkName: "pages/admin/product-types/_id/index" */))
const _39686de7 = () => interopDefault(import('../src/pages/admin/products/_id/index.vue' /* webpackChunkName: "pages/admin/products/_id/index" */))
const _75257cc7 = () => interopDefault(import('../src/pages/admin/promopage/_id/index.vue' /* webpackChunkName: "pages/admin/promopage/_id/index" */))
const _68c6108a = () => interopDefault(import('../src/pages/admin/publications/_id/index.vue' /* webpackChunkName: "pages/admin/publications/_id/index" */))
const _10c64e08 = () => interopDefault(import('../src/pages/admin/rates/_id/index.vue' /* webpackChunkName: "pages/admin/rates/_id/index" */))
const _55524b23 = () => interopDefault(import('../src/pages/admin/segments/_id/index.vue' /* webpackChunkName: "pages/admin/segments/_id/index" */))
const _6dcf8de2 = () => interopDefault(import('../src/pages/admin/showcases/_id/index.vue' /* webpackChunkName: "pages/admin/showcases/_id/index" */))
const _527ac608 = () => interopDefault(import('../src/pages/admin/stories/_id/index.vue' /* webpackChunkName: "pages/admin/stories/_id/index" */))
const _0341dec9 = () => interopDefault(import('../src/pages/admin/substatuses/_id/index.vue' /* webpackChunkName: "pages/admin/substatuses/_id/index" */))
const _3f5bbee0 = () => interopDefault(import('../src/pages/admin/tickets/_id/index.vue' /* webpackChunkName: "pages/admin/tickets/_id/index" */))
const _6e7b07fc = () => interopDefault(import('../src/pages/admin/trigger-events/_usersType/index.vue' /* webpackChunkName: "pages/admin/trigger-events/_usersType/index" */))
const _255bb29e = () => interopDefault(import('../src/pages/admin/users/_id/index.vue' /* webpackChunkName: "pages/admin/users/_id/index" */))
const _356b8fca = () => interopDefault(import('../src/pages/admin/videos/_id/index.vue' /* webpackChunkName: "pages/admin/videos/_id/index" */))
const _1067fe47 = () => interopDefault(import('../src/pages/admin/withdrawals/_id/index.vue' /* webpackChunkName: "pages/admin/withdrawals/_id/index" */))
const _6fbc2b28 = () => interopDefault(import('../src/pages/admin/categories/_id/PrecalculationSettings.vue' /* webpackChunkName: "pages/admin/categories/_id/PrecalculationSettings" */))
const _01004486 = () => interopDefault(import('../src/pages/admin/communication-chains/_id/editor/index.vue' /* webpackChunkName: "pages/admin/communication-chains/_id/editor/index" */))
const _84e1fa8c = () => interopDefault(import('../src/pages/admin/trigger-events/_usersType/email/_id/index.vue' /* webpackChunkName: "pages/admin/trigger-events/_usersType/email/_id/index" */))
const _65901799 = () => interopDefault(import('../src/pages/admin/trigger-events/_usersType/notification/_id/index.vue' /* webpackChunkName: "pages/admin/trigger-events/_usersType/notification/_id/index" */))
const _467b7d9a = () => interopDefault(import('../src/pages/admin/trigger-events/_usersType/email/_id/preview/index.vue' /* webpackChunkName: "pages/admin/trigger-events/_usersType/email/_id/preview/index" */))
const _740c0712 = () => interopDefault(import('../src/pages/admin/trigger-events/_usersType/notification/_id/preview/index.vue' /* webpackChunkName: "pages/admin/trigger-events/_usersType/notification/_id/preview/index" */))
const _a59f2a14 = () => interopDefault(import('../src/pages/admin/help/_sectionId/_answerId/index.vue' /* webpackChunkName: "pages/admin/help/_sectionId/_answerId/index" */))
const _6667ac4d = () => interopDefault(import('../src/pages/admin/index.vue' /* webpackChunkName: "pages/admin/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/articles-category",
    component: _47d3b168,
    name: "articles-category"
  }, {
    path: "/banners",
    component: _eb3a8ff6,
    name: "banners"
  }, {
    path: "/bonus-conditions",
    component: _4b55d13e,
    name: "bonus-conditions"
  }, {
    path: "/bonuses",
    component: _29f2bb70,
    children: [{
      path: "",
      component: _96db5a6a,
      name: "bonuses"
    }, {
      path: ":id",
      component: _211f1036,
      name: "bonuses-id"
    }]
  }, {
    path: "/cabinets",
    component: _1a6e4ff3,
    name: "cabinets"
  }, {
    path: "/calculator-integrations",
    component: _8c44b4f0,
    name: "calculator-integrations"
  }, {
    path: "/campaigns",
    component: _d3ec9a3e,
    name: "campaigns"
  }, {
    path: "/categories",
    component: _24dfb474,
    name: "categories"
  }, {
    path: "/communication-chains",
    component: _d13a0cfe,
    name: "communication-chains"
  }, {
    path: "/content",
    component: _1b50e192,
    name: "content"
  }, {
    path: "/contracts",
    component: _ec9224c2,
    name: "contracts"
  }, {
    path: "/courses",
    component: _56ba8894,
    name: "courses"
  }, {
    path: "/excel-operations",
    component: _22c7444c,
    name: "excel-operations"
  }, {
    path: "/fast-filters",
    component: _d736b5bc,
    name: "fast-filters"
  }, {
    path: "/fields",
    component: _4a223351,
    name: "fields"
  }, {
    path: "/first-page",
    component: _262341e4,
    name: "first-page"
  }, {
    path: "/formulas",
    component: _72700c25,
    name: "formulas"
  }, {
    path: "/help",
    component: _a5f8f5ce,
    name: "help"
  }, {
    path: "/integrations",
    component: _37807077,
    name: "integrations"
  }, {
    path: "/integrations-ast",
    component: _5896d928,
    name: "integrations-ast"
  }, {
    path: "/managers-office",
    component: _f65b717e,
    name: "managers-office"
  }, {
    path: "/mass-finances",
    component: _7145d860,
    name: "mass-finances"
  }, {
    path: "/mass-offers",
    component: _6cfd49e4,
    name: "mass-offers"
  }, {
    path: "/mass-operations",
    component: _4b2aed23,
    name: "mass-operations"
  }, {
    path: "/mass-statuses",
    component: _f398b952,
    name: "mass-statuses"
  }, {
    path: "/mortgage-calculator",
    component: _10a3de91,
    name: "mortgage-calculator"
  }, {
    path: "/notifications",
    component: _3a830ab4,
    name: "notifications"
  }, {
    path: "/offers",
    component: _4753f32c,
    children: [{
      path: "",
      component: _1a9f2ea2,
      name: "offers"
    }, {
      path: ":id",
      component: _54b7941a,
      name: "offers-id"
    }]
  }, {
    path: "/partners",
    component: _080220fa,
    name: "partners"
  }, {
    path: "/popups",
    component: _af1fff42,
    name: "popups"
  }, {
    path: "/product-types",
    component: _07e4cc19,
    name: "product-types"
  }, {
    path: "/products",
    component: _744830fc,
    name: "products"
  }, {
    path: "/promo-constructor",
    component: _ea25510c,
    name: "promo-constructor"
  }, {
    path: "/promopage",
    component: _379fafdc,
    name: "promopage"
  }, {
    path: "/publications",
    component: _62ce9d1f,
    name: "publications"
  }, {
    path: "/rates",
    component: _1a2f6e91,
    name: "rates"
  }, {
    path: "/requests-limiter",
    component: _d95b4c18,
    name: "requests-limiter"
  }, {
    path: "/requests-limiter.vue",
    component: _2a3a600f,
    name: "requests-limiter.vue"
  }, {
    path: "/segments",
    component: _316be790,
    name: "segments"
  }, {
    path: "/settings",
    component: _17b80e0a,
    name: "settings"
  }, {
    path: "/showcases",
    component: _2aabd024,
    name: "showcases"
  }, {
    path: "/stories",
    component: _4a519ade,
    name: "stories"
  }, {
    path: "/substatuses",
    component: _5bb162de,
    name: "substatuses"
  }, {
    path: "/tickets",
    component: _5f80bfb6,
    name: "tickets"
  }, {
    path: "/user-id-convert",
    component: _4ce3ccfe,
    name: "user-id-convert"
  }, {
    path: "/users",
    component: _73b1dec6,
    name: "users"
  }, {
    path: "/video-comments",
    component: _562cdb44,
    name: "video-comments"
  }, {
    path: "/video-tags",
    component: _89c8847a,
    name: "video-tags"
  }, {
    path: "/videos",
    component: _506f3530,
    name: "videos"
  }, {
    path: "/withdrawals",
    component: _2f5ef15c,
    name: "withdrawals"
  }, {
    path: "/auth/login",
    component: _6f9abc52,
    name: "auth-login"
  }, {
    path: "/auth/success",
    component: _2b0e57f1,
    name: "auth-success"
  }, {
    path: "/courses/blocks",
    component: _6db89e55,
    name: "courses-blocks"
  }, {
    path: "/courses/categories",
    component: _b75bcd2a,
    name: "courses-categories"
  }, {
    path: "/courses/tags",
    component: _a7d433b0,
    name: "courses-tags"
  }, {
    path: "/menu/customer-portal",
    component: _179d37e3,
    name: "menu-customer-portal"
  }, {
    path: "/promo-constructor/preview",
    component: _13dfd5f3,
    name: "promo-constructor-preview"
  }, {
    path: "/withdrawals/archive",
    component: _76040b62,
    name: "withdrawals-archive"
  }, {
    path: "/mortgage-calculator/tables/mortgage-calculator-banks",
    component: _1e0705a4,
    name: "mortgage-calculator-tables-mortgage-calculator-banks"
  }, {
    path: "/mortgage-calculator/tables/mortgage-calculator-insurances",
    component: _14184b3c,
    name: "mortgage-calculator-tables-mortgage-calculator-insurances"
  }, {
    path: "/courses/blocks/:id",
    component: _007ef280,
    name: "courses-blocks-id"
  }, {
    path: "/courses/categories/:id",
    component: _085a86d6,
    name: "courses-categories-id"
  }, {
    path: "/courses/tags/:id",
    component: _8880a5da,
    name: "courses-tags-id"
  }, {
    path: "/menu/customer-portal/:parentId",
    component: _571655d0,
    name: "menu-customer-portal-parentId"
  }, {
    path: "/menu/customer-portal/:parentId?/:id",
    component: _a7a4b7fa,
    name: "menu-customer-portal-parentId-id"
  }, {
    path: "/articles-category/:id",
    component: _fc9a8792,
    name: "articles-category-id"
  }, {
    path: "/banners/:id",
    component: _21664870,
    name: "banners-id"
  }, {
    path: "/bonus-conditions/:id",
    component: _d76fc5ae,
    name: "bonus-conditions-id"
  }, {
    path: "/cabinets/:id",
    component: _10e8395e,
    name: "cabinets-id"
  }, {
    path: "/campaigns/:id",
    component: _bf0e0cee,
    name: "campaigns-id"
  }, {
    path: "/categories/:id",
    component: _0321755f,
    name: "categories-id"
  }, {
    path: "/communication-chains/:id",
    component: _49316bec,
    name: "communication-chains-id"
  }, {
    path: "/content/:id",
    component: _47d836a2,
    name: "content-id"
  }, {
    path: "/contracts/:id",
    component: _4a12210a,
    name: "contracts-id"
  }, {
    path: "/courses/:id",
    component: _7fab0ba1,
    name: "courses-id"
  }, {
    path: "/excel-finance/:id",
    component: _d2a6a926,
    name: "excel-finance-id"
  }, {
    path: "/excel-statuses/:id",
    component: _362559b9,
    name: "excel-statuses-id"
  }, {
    path: "/fast-filters/:id",
    component: _50425f0d,
    name: "fast-filters-id"
  }, {
    path: "/fields/:id",
    component: _99c18488,
    name: "fields-id"
  }, {
    path: "/formulas/:id",
    component: _4726e6e0,
    name: "formulas-id"
  }, {
    path: "/help/:sectionId",
    component: _7d15ada6,
    name: "help-sectionId"
  }, {
    path: "/mass-finances/:id",
    component: _5ee7c6bb,
    name: "mass-finances-id"
  }, {
    path: "/mass-offers/:id",
    component: _ca0b820e,
    name: "mass-offers-id"
  }, {
    path: "/mass-operations/:id",
    component: _2ab2be8e,
    name: "mass-operations-id"
  }, {
    path: "/mass-statuses/:id",
    component: _ad658a7c,
    name: "mass-statuses-id"
  }, {
    path: "/notifications/:id",
    component: _ea245ade,
    name: "notifications-id"
  }, {
    path: "/partners/:id",
    component: _72fe90ee,
    name: "partners-id"
  }, {
    path: "/popups/:id",
    component: _6c83d3ca,
    name: "popups-id"
  }, {
    path: "/product-types/:id",
    component: _28d83284,
    name: "product-types-id"
  }, {
    path: "/products/:id",
    component: _39686de7,
    name: "products-id"
  }, {
    path: "/promopage/:id",
    component: _75257cc7,
    name: "promopage-id"
  }, {
    path: "/publications/:id",
    component: _68c6108a,
    name: "publications-id"
  }, {
    path: "/rates/:id",
    component: _10c64e08,
    name: "rates-id"
  }, {
    path: "/segments/:id",
    component: _55524b23,
    name: "segments-id"
  }, {
    path: "/showcases/:id",
    component: _6dcf8de2,
    name: "showcases-id"
  }, {
    path: "/stories/:id",
    component: _527ac608,
    name: "stories-id"
  }, {
    path: "/substatuses/:id",
    component: _0341dec9,
    name: "substatuses-id"
  }, {
    path: "/tickets/:id",
    component: _3f5bbee0,
    name: "tickets-id"
  }, {
    path: "/trigger-events/:usersType",
    component: _6e7b07fc,
    name: "trigger-events-usersType"
  }, {
    path: "/users/:id",
    component: _255bb29e,
    name: "users-id"
  }, {
    path: "/videos/:id",
    component: _356b8fca,
    name: "videos-id"
  }, {
    path: "/withdrawals/:id",
    component: _1067fe47,
    name: "withdrawals-id"
  }, {
    path: "/categories/:id/PrecalculationSettings",
    component: _6fbc2b28,
    name: "categories-id-PrecalculationSettings"
  }, {
    path: "/communication-chains/:id?/editor",
    component: _01004486,
    name: "communication-chains-id-editor"
  }, {
    path: "/trigger-events/:usersType?/email/:id",
    component: _84e1fa8c,
    name: "trigger-events-usersType-email-id"
  }, {
    path: "/trigger-events/:usersType?/notification/:id",
    component: _65901799,
    name: "trigger-events-usersType-notification-id"
  }, {
    path: "/trigger-events/:usersType?/email/:id/preview",
    component: _467b7d9a,
    name: "trigger-events-usersType-email-id-preview"
  }, {
    path: "/trigger-events/:usersType?/notification/:id/preview",
    component: _740c0712,
    name: "trigger-events-usersType-notification-id-preview"
  }, {
    path: "/help/:sectionId/:answerId",
    component: _a59f2a14,
    name: "help-sectionId-answerId"
  }, {
    path: "/",
    component: _6667ac4d,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
