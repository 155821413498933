var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c(_vm.componentType, _vm._b({
    tag: "component",
    staticClass: "header-navigation-link",
    attrs: {
      "to": _vm.to
    }
  }, 'component', _vm.$attrs, false), [_vm._t("default")], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }