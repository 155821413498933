import { render, staticRenderFns } from "./-HeaderNavigation.vue?vue&type=template&id=8ef36d72&scoped=true&"
import script from "./-HeaderNavigation.vue?vue&type=script&lang=ts&"
export * from "./-HeaderNavigation.vue?vue&type=script&lang=ts&"
import style0 from "./-HeaderNavigation.vue?vue&type=style&index=0&id=8ef36d72&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8ef36d72",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppCopy: require('/var/www/app/src/components/ui/AppCopy.vue').default,AppTooltip: require('/var/www/app/src/components/ui/AppTooltip.vue').default})
