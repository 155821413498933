var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('header', {
    staticClass: "the-header"
  }, [_c('nuxt-link', {
    staticClass: "the-header__logo-link",
    attrs: {
      "to": "/"
    }
  }, [_c('logo-admin', {
    staticClass: "the-header__logo"
  })], 1), _vm._v(" "), _c('header-navigation', {
    staticClass: "the-header__navigation"
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }