var render = function () {
  var _vm$item$portalMenuSu;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('li', {
    staticClass: "catalog-item"
  }, [_c('nuxt-link', {
    class: {
      'catalog-item': true,
      'catalog-item--nested': _vm.nested
    },
    attrs: {
      "exact-active-class": "catalog-item--active",
      "to": _vm.item.link
    },
    domProps: {
      "textContent": _vm._s(_vm.item.name)
    }
  }), _vm._v(" "), (_vm$item$portalMenuSu = _vm.item.portalMenuSubItems) !== null && _vm$item$portalMenuSu !== void 0 && _vm$item$portalMenuSu.length ? _c('ul', {
    staticClass: "catalog-item__nested-items"
  }, _vm._l(_vm.item.portalMenuSubItems, function (nestedItem) {
    return _c('catalog-item', {
      key: nestedItem.id,
      attrs: {
        "item": nestedItem,
        "nested": ""
      }
    });
  }), 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }