

























import { defineComponent, PropType } from '@nuxtjs/composition-api'

import { Components as Admin } from '@/types/client.admin'
import { createPropValidator } from '@/composables/prop-validator'

export const isPortalSection = (item: any): item is Admin.Schemas.PortalMenuItem => (
    item
    && typeof item === 'object'
    && (!item.name || typeof item.name === 'string')
    && (!item.id || typeof item.id === 'string')
    && (!item.link || typeof item.link === 'string')
    && (!item.portalMenuSubItems || item.portalMenuSubItems.every(isPortalSection))
)

export default defineComponent({
    name: 'CatalogItem',
    props: {
        item: {
            type: Object as PropType<Admin.Schemas.PortalMenuItem>,
            validator: createPropValidator({
                componentName: 'CatalogItem',
                propertyName: 'item',
                validator: isPortalSection
            }),
            required: true
        },
        nested: {
            type: Boolean,
            default: false
        }
    }
})
