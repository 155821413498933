












import { defineComponent } from '@nuxtjs/composition-api'
import LogoAdmin from '@/assets/img/admin/logo-admin.svg'

import HeaderNavigation from './-HeaderNavigation.vue'

export default defineComponent({
    components: {
        LogoAdmin,
        HeaderNavigation
    }
})
