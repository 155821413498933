var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('nav', {
    staticClass: "admin-navigation"
  }, [_c('div', {
    staticClass: "admin-navigation__user-info"
  }, [_c('app-tooltip', {
    staticClass: "admin-navigation__tooltip",
    attrs: {
      "placement": "bottom",
      "hoverable": "",
      "offset": 20
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function () {
        var _vm$user$profile, _vm$user$profile2;

        return [_c('div', {
          staticClass: "admin-navigation__user-info-name"
        }, [_vm._v("\n                    " + _vm._s((((_vm$user$profile = _vm.user.profile) === null || _vm$user$profile === void 0 ? void 0 : _vm$user$profile.familyName) + " " + ((_vm$user$profile2 = _vm.user.profile) === null || _vm$user$profile2 === void 0 ? void 0 : _vm$user$profile2.name))) + "\n                ")])];
      },
      proxy: true
    }, {
      key: "tooltip",
      fn: function () {
        var _vm$user$email, _vm$user$id;

        return [_c('div', {
          staticClass: "admin-navigation__user-info-email"
        }, [_vm._v("\n                    " + _vm._s((_vm$user$email = _vm.user.email) === null || _vm$user$email === void 0 ? void 0 : _vm$user$email.email) + "\n                ")]), _vm._v(" "), _c('div', {
          staticClass: "admin-navigation__user-info-id"
        }, [_c('app-copy', {
          staticClass: "catalog-profile__id-copy",
          attrs: {
            "no-icon": "",
            "to-copy": _vm.user.id
          }
        }, [_vm._v("\n                        ID\n                        "), _c('span', {
          staticClass: "catalog-profile__id-copy-item"
        }, [_c('strong', [_vm._v("..." + _vm._s(((_vm$user$id = _vm.user.id) === null || _vm$user$id === void 0 ? void 0 : _vm$user$id.slice(-6)) || ''))])])])], 1)];
      },
      proxy: true
    }])
  })], 1), _vm._v(" "), _c('header-navigation-link', {
    staticClass: "admin-navigation__link",
    attrs: {
      "href": "https://lk.workle.ru",
      "target": "_blank"
    }
  }, [_c('external-link-icon', {
    staticClass: "admin-navigation__icon admin-navigation__icon--chevrons"
  }), _vm._v("\n        Открыть сайт\n    ")], 1), _vm._v(" "), _c('header-navigation-link', {
    staticClass: "admin-navigation__link",
    attrs: {
      "href": "javascript:void(0)"
    },
    nativeOn: {
      "click": function ($event) {
        return _vm.logout.apply(null, arguments);
      }
    }
  }, [_c('log-out-icon', {
    staticClass: "admin-navigation__icon admin-navigation__icon--logout"
  }), _vm._v("\n        Выйти\n    ")], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }