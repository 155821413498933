var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', _vm._g({
    attrs: {
      "width": "178",
      "height": "22",
      "viewBox": "0 0 178 22",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, _vm.$listeners), [_c('path', {
    attrs: {
      "d": "M116.975 21.807l-1.139-3.453h-7.347l-1.173 3.453a.247.247 0 01-.228.159h-3.85c-.16 0-.273-.16-.228-.318l6.208-17.626c.456-1.33 1.435-2.022 3.076-2.022 1.56 0 2.597.716 3.029 1.988l6.003 17.649c.057.147-.057.306-.227.306h-3.907c-.092.034-.183-.034-.217-.136zm-4.75-15.412l-2.893 8.78h5.684l-2.791-8.78zM134.346 2.023c.194 0 .364.159.364.352l.023 16.66c0 1.239-.558 1.931-1.799 2.272-1.96.557-3.315.659-4.614.659-4.374 0-6.925-2.669-6.925-7.325 0-4.68 2.221-7.417 6.527-7.417 1.036 0 2.073.284 3.189.602V2.375c0-.193.159-.364.365-.364.945.012 1.89.012 2.87.012zm-9.352 12.595c0 2.726 1.037 4.44 3.531 4.44.741 0 1.299-.079 2.575-.374V10.7c-1.481-.398-2.119-.477-2.711-.5-2.438 0-3.395 1.613-3.395 4.418zM140.132 21.966h-2.973a.33.33 0 01-.33-.33V10.212c0-1.272.638-1.92 1.857-2.283 1.617-.51 3.03-.692 4.374-.692 1.72 0 2.996.374 3.85 1.09 1.298-.59 2.916-1.09 4.613-1.09 3.85 0 5.388 2.044 5.388 5.11v9.302a.33.33 0 01-.33.33h-2.951a.33.33 0 01-.33-.33v-8.995c0-1.306-.581-2.283-2.176-2.283-1.173 0-1.959.318-2.676.59.136.341.262.853.262 1.523v9.165a.33.33 0 01-.331.33h-2.95a.33.33 0 01-.33-.33v-8.995c0-1.351-.536-2.283-2.199-2.283-.615 0-1.275.057-2.437.42v10.847a.33.33 0 01-.331.329zM160.966 5.986c-1.333 0-2.096-.772-2.096-1.998 0-1.193.775-1.965 2.096-1.965 1.321 0 2.096.772 2.096 1.965 0 1.226-.763 1.998-2.096 1.998zm-1.8 15.64V8.121c0-.194.148-.341.342-.341h2.927c.194 0 .342.147.342.34v13.504c0 .193-.148.341-.342.341h-2.927a.342.342 0 01-.342-.34zM168.45 21.977h-3.007a.342.342 0 01-.342-.34V10.21c0-1.272.638-1.942 1.857-2.283 2.016-.556 3.451-.692 4.727-.692 4.248 0 5.923 2.18 5.923 5.235v9.154c0 .182-.148.34-.342.34h-2.961a.342.342 0 01-.342-.34V12.79c0-1.351-.581-2.419-2.472-2.419-.638 0-1.298.057-2.711.42v10.835a.329.329 0 01-.33.352z",
      "fill": "#049FFF"
    }
  }), _c('path', {
    attrs: {
      "d": "M23.915 2.023h-3.372c-.296 0-.319.25-.319.25L17.97 17.07 14.46 3.681C14.198 2.693 13.39 2 12.194 2c-1.208 0-2.04.693-2.29 1.68l-3.6 13.357-2.21-14.708s-.045-.306-.307-.306H.28c-.342 0-.273.409-.273.409L3.218 19.91c.239 1.306 1.173 2.067 2.768 2.067 1.515 0 2.528-.624 2.87-1.874l3.28-11.822 3.133 11.822C15.611 21.375 16.602 22 18.162 22c1.56 0 2.506-.75 2.791-2.067L24.2 2.375c.012-.023.08-.352-.284-.352zm7.335 5.213c-4.283 0-6.994 2.657-6.994 7.348 0 4.656 2.586 7.382 6.915 7.382 4.282 0 7.039-2.726 7.039-7.405.011-4.668-2.643-7.325-6.96-7.325zm-.205 12.027c-2.198 0-3.394-1.624-3.394-4.668 0-2.964 1.105-4.634 3.326-4.634 2.198 0 3.417 1.636 3.417 4.634-.011 3.021-1.162 4.668-3.349 4.668zM47.721 7.78c-2.62 0-4.055 0-5.877.5-1.208.34-1.834.988-1.834 2.26v11.141s0 .284.296.284 2.586-.011 2.893-.011c.32 0 .32-.284.32-.284V11.358c1.252-.352 2.505-.477 4.168-.5.103 0 .296-.09.296-.295V8.076c.023-.182-.102-.295-.262-.295zm14.831 13.776l-4.203-9.086s3.656-4.066 3.782-4.202c.125-.136.148-.488-.206-.488H58.35c-.205 0-.274.113-.274.113l-4.875 5.85V2.283c0-.273-.296-.284-.296-.284h-2.962s-.261 0-.261.318v19.364c0 .193.136.284.296.284h2.984c.262 0 .273-.295.273-.295v-1.863c-.022-1.556.376-2.249 1.162-3.112l1.15-1.26 2.996 6.348s.08.17.251.17h3.508c.422.035.251-.397.251-.397z",
      "fill": "#BFBFBF"
    }
  }), _c('path', {
    attrs: {
      "d": "M69.42 2.011h-2.893c-.296 0-.341.296-.341.296v19.341c0 .159.113.318.284.318h2.985c.296 0 .273-.273.273-.273V2.341c.012-.205-.102-.33-.307-.33zM84.833 14.3c0-4.804-2.54-7.053-6.584-7.053-3.69 0-6.788 2.283-6.788 7.428 0 4.883 2.79 7.291 7.142 7.291 1.31 0 4.522-.42 5.49-.534.16-.022.262-.125.262-.34v-2.26c0-.171-.114-.341-.308-.319-1.014.137-4.112.432-5.114.432-2.21 0-3.793-.977-3.987-3.214h8.418c1.082-.023 1.47-.409 1.47-1.431zm-9.898-1.215c.136-2.158 1.298-3.146 3.212-3.146 2.255 0 3.223 1.226 3.223 3.146h-6.435zM86 6.867V.977c0-.407.18-.623.582-.745.482-.149.984-.225 1.488-.224 1.412 0 2.232.883 2.232 2.397 0 1.532-.718 2.424-2.104 2.424a3.433 3.433 0 01-1.026-.191v2.23H86zm3.14-4.445c0-.891-.335-1.445-1.138-1.445-.28.006-.558.044-.83.112v2.614c.479.13.684.156.872.156.787 0 1.095-.52 1.095-1.437zM93.796 1.003a5.508 5.508 0 00-1.497.165v3.635h-1.164V.995c0-.424.206-.64.6-.753C92.35.07 92.88.017 93.796.017v.986zM96.551 4.855c-1.42 0-2.283-.882-2.283-2.423 0-1.558.906-2.432 2.31-2.432 1.411 0 2.283.874 2.283 2.432 0 1.549-.907 2.423-2.31 2.423zm0-3.964c-.727 0-1.085.554-1.085 1.541 0 1.013.393 1.54 1.111 1.54.719 0 1.095-.536 1.095-1.54 0-.996-.402-1.54-1.12-1.54z",
      "fill": "#049FFF"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }