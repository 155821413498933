










import {
    PropType,
    defineComponent
} from '@nuxtjs/composition-api'

import { Components as Admin } from '@/types/client.admin'

export default defineComponent({
    props: {
        menuItems: {
            type: Array as PropType<Admin.Schemas.PortalMenuItem[]>,
            default: () => []
        }
    }
})
