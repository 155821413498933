import { render, staticRenderFns } from "./admin.vue?vue&type=template&id=94ae6454&scoped=true&"
import script from "./admin.vue?vue&type=script&lang=ts&"
export * from "./admin.vue?vue&type=script&lang=ts&"
import style0 from "./admin.vue?vue&type=style&index=0&lang=css&"
import style1 from "./admin.vue?vue&type=style&index=1&id=94ae6454&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "94ae6454",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TheHeader: require('/var/www/app/src/components/admin/TheHeader/index.vue').default,TheCatalog: require('/var/www/app/src/components/admin/TheCatalog/index.vue').default,TheResultModal: require('/var/www/app/src/components/ui/TheResultModal.vue').default})
