




















































import { defineComponent, onMounted, useContext } from '@nuxtjs/composition-api'

import ExternalLinkIcon from '@/assets/img/external-link.svg'
import LogOutIcon from '@/assets/img/log-out.svg'
import HeaderNavigationLink from './-HeaderNavigationLink.vue'

import { fetchUser, user } from '@/composables/user'

export default defineComponent({
    components: {
        ExternalLinkIcon,
        LogOutIcon,
        HeaderNavigationLink
    },
    setup () {
        const context = useContext()
        const { $auth } = context
        const logout = () => $auth.logout()

        onMounted(() => {
            fetchUser(context)
        })

        return {
            logout,
            user
        }
    }
})
