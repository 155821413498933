











import { PropType, computed, defineComponent } from '@nuxtjs/composition-api'
import { RawLocation } from 'vue-router'

export default defineComponent({
    props: {
        to: {
            type: [ Object, String ] as PropType<RawLocation | null>,
            default: null
        }
    },
    setup: (props) => {
        const componentType = computed(() => (props.to ? 'nuxt-link' : 'a'))

        return {
            componentType
        }
    }
})
