var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "admin",
    on: {
      "wheel": _vm.onScroll,
      "touchmove": _vm.onScroll
    }
  }, [_c('div', {
    staticClass: "admin__content-wrapper"
  }, [_c('the-header', {
    staticClass: "admin__header"
  }), _vm._v(" "), _c('the-catalog', {
    staticClass: "admin__catalog",
    attrs: {
      "menu-items": _vm.menu
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "admin__content"
  }, [_c('client-only', [_c('nuxt')], 1)], 1)], 1), _vm._v(" "), _c('transition', {
    attrs: {
      "name": "fade"
    }
  }, [_vm.scrolledSize > 100 ? _c('button', {
    staticClass: "admin__to-top",
    on: {
      "click": _vm.scrollToTop
    }
  }, [_c('down-arrow-icon', {
    staticClass: "admin__to-top-icon"
  })], 1) : _vm._e()]), _vm._v(" "), _c('the-result-modal', {
    ref: "$resultModal",
    attrs: {
      "name": "admin-result-modal"
    },
    on: {
      "on-submit": _vm.closeModal
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }