



































import {
    defineComponent,
    nextTick,
    onMounted,
    ref,
    useContext,
    watch
} from '@nuxtjs/composition-api'

import { $resultModal, closeModal } from '@/composables/result-modal'

import { Components as Admin } from '@/types/client.admin'

import DownArrowIcon from '@/assets/img/arrow-down.svg'

export default defineComponent({
    name: 'Admin',
    auth: true,
    components: {
        DownArrowIcon
    },
    middleware: 'auth',
    setup () {
        const { $apiAdmin, route } = useContext()
        const scrolledSize = ref(0)

        const menu = ref<Admin.Schemas.PortalMenuItem[]>([])
        const fetchAdminCatalog = async () => {
            const { data = [] } = await $apiAdmin.accessMenuItemsGetPortalMenuItems()

            menu.value = data
        }

        const onScroll = () => {
            scrolledSize.value = document.documentElement.scrollTop
        }

        const scrollToTop = () => {
            nextTick(() => {
                document.documentElement.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                })

                onScroll()
            })
        }

        watch(
            () => route.value,
            () => { scrolledSize.value = 0 }
        )

        onMounted(fetchAdminCatalog)

        return {
            $resultModal,
            menu,
            scrolledSize,
            scrollToTop,
            onScroll,
            closeModal
        }
    }
})
