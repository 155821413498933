var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('nav', {
    staticClass: "the-catalog"
  }, _vm._l(_vm.menuItems, function (item) {
    return _c('catalog-item', {
      key: item.id,
      attrs: {
        "item": item
      }
    });
  }), 1);
}
var staticRenderFns = []

export { render, staticRenderFns }